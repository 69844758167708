
import { FileResponseEntity } from "@/models/Upload";
import { isNull } from "lodash";

export default {
	name: "CardFileInput",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {},
		customClass: {
			type: String,
			default: "label-fixed static",
		},
		cardStyles: {
			type: String,
			default: "px-2 mb-2 static",
		},
		placeholder: {
			type: [String, Object],
			default: "",
		},
		label: {
			type: [String, Object],
			default: "",
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		prepend_icon: {
			type: String,
			default: "mdi-upload",
		},
		accept: {
			type: String,
			default: "",
		},
		hideInput: {
			type: Boolean,
			default: false,
		},
		reference: {
			type: String,
			default: "reference",
		},
	},
	data: function () {
		return {
			file: new FileResponseEntity(),
		};
	},
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
		getCustomClass() {
			return `${this.customClass} ${
				this.disabled || this.readonly ? "disabled" : ""
			}`;
		},
	},
	methods: {
		async handleUpload(event: any) {
			if (!isNull(event)) {
				(this.file as FileResponseEntity).setData({
					uploaded: true,
					isValid: true,
					messages: [],
				});
			}
			this.$emit("response", { file: this.file });
		},
		handleUploadClear() {
			this.file = new FileResponseEntity();
		},
		openUploader() {
			const component: any = this.$refs[this.reference]?.$refs.input;

			if (
				!component &&
				(typeof component.focus !== "function" ||
					typeof component.click !== "function")
			)
				return;

			component?.focus();
			component?.click();
		},
	},
	watch: {},
};
